import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: '#181818',
        color: 'white'
    },
    container: {
        /*marginTop: theme.spacing(15),
        marginBottom: theme.spacing(30),
        display: 'flex',
        position: 'relative',*/
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    /*item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //padding: theme.spacing(0, 5),
    },*/
    image: {
        width: '100%',
        height: '600px',
        [theme.breakpoints.down('sm')]: {
            height: '400px',
        },
    },
    title: {
        width: '100%'
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
    },
    gridItem: {
        padding: 0
    },
    containerDesc: {
        padding: '125px',
        [theme.breakpoints.down('sm')]: {
            padding: '50px',
        },
    }
});

function Areas(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <div className={classes.item}>
                        <img className={classes.image} src={require("../images/objetivo.jpg")} alt="Areas" />
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <div className={classes.containerDesc}>
                        <Typography variant="h5" className={classes.title}>
                            Nuestros Servicios
                        </Typography>
                        <br />
                        <Typography>
                            {'- Especialistas en Derecho laboral: despidos, accidentes y enfermedades profesionales, servicio doméstico.'}<br/>
                            {'- Divorcio, unión convivencial, alimentos, sucesiones.'}<br/>
                            {'- Asesoramiento a empresas, sociedades, trámites ante IGJ, registro de marca.'}<br />
                            {'- Derecho del consumo.'}<br />
                            {'- Accidentes de tránsito, multas.'}<br />
                            {'- Representación en audiencias ante SECLO, COPREC, MEDIACIONES PREJUDICIALES, MINISTERIO DE TRABAJO.'}<br />
                            {'- Contratos.'}<br />
                            {'- Derecho previsional. Jubilaciones y pensiones.'}<br />
                            {'- Servicios de gestoría en Ciudad Autónoma de Buenos Aires y Provincia de Buenos Aires.'}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
}

export default withStyles(styles)(Areas);