import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Link from '@material-ui/core/Link';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';
import { Link as LinkScroll } from 'react-scroll';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#181818',
        position: 'fixed'
    },
    menuButton: {
        //marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: '10px'
        },
    },
    linkImage: {
        flexGrow: 1,
    },
    image: {        
        height: '70px',
        float: 'left'
    },
    descImageTop: {
        color: 'white',
        position: 'relative',
        top: '15px',
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    descImageBottom: {
        color: 'white',
        fontSize: '12px',
        letterSpacing: '3.5px',
        position: 'relative',
        bottom: '10px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    }
}));

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 450,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        style: trigger ? { backgroundColor: '#FFF', color: 'black' } : null
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function Header(props) {
    const classes = useStyles();
    return (
        <ElevationScroll {...props}>
            <div className={classes.root}>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar>
                        {/*<Typography variant="h6" className={classes.title}>
                            Estudio Fuentes Tedesco & Asociados
                        </Typography>*/}
                        <Link href="#" className={classes.linkImage} underline="none">
                            <img
                                className={classes.image}
                                src={require("../images/logo2.png")}
                                alt="logo"
                            />
                            <div>
                                <Typography className={classes.descImageTop}>
                                    Fuentes Tedesco & Asociados
                                </Typography>
                                <br/>
                                <Typography className={classes.descImageBottom}>
                                    Estudio Jurídico Integral
                                </Typography>
                            </div>
                        </Link>                    
                        <Link href="https://www.facebook.com/Estudio-jur%C3%ADdico-Fuentes-Tedesco-y-asoc-100262941477458" target="_blank" color="inherit">
                            <IconButton className={classes.menuButton} color="inherit" aria-label="menu">
                                <FacebookIcon />
                            </IconButton>
                        </Link>
                        <Link href="https://instagram.com/estudio_fuentes" target="_blank" color="inherit">
                            <IconButton className={classes.menuButton} color="inherit" aria-label="menu">
                                <InstagramIcon />
                            </IconButton>
                        </Link>
                        <LinkScroll smooth={true} to="contacto">
                            <Button color="inherit">Contacto</Button>
                        </LinkScroll>
                    </Toolbar>
                </AppBar>
            </div>
        </ElevationScroll>
    );
}