import React, { useState } from 'react';
import { withStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Box from '@mui/material/Box';
import { Element } from 'react-scroll';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../components/SnackbarContentWrapper';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: 'white',
        overflow: 'hidden',
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5),
        backgroundColor: 'black',
        color: 'white'
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buoy: {
        width: 60,
    },
    textField: {
        color: 'white',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        width: '100%',
        textAlign: "center",
        /*[theme.breakpoints.down('xs')]: {
            width: '350px'
        },*/
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    contactIcon: {
        position: 'relative',
        top: '5px',
        paddingRight: '10px'
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000'
        },
    },
});

function Contacto(props) {
    const { classes } = props;
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [snackbarOpen, setOpen] = useState(false);
    const [snackbarMessage, setMessage] = useState();
    const [snackbarVariant, setVariant] = useState();
    const [loading, setLoading] = useState(false);

    function handleChangeNombre(event) {
        setNombre(event.target.value);
    }

    function handleChangeEmail(event) {
        setEmail(event.target.value);
    }

    function handleChangeMensaje(event) {
        setMensaje(event.target.value);
    }

    function handleSubmit() {        
        if (nombre === '' || email === '' || mensaje === '') {
            setVariant("error");
            setMessage("Todos los campos son obligatorios!");
            setOpen(true);
        }
        else if (!validateEmail()) {
            setVariant("error");
            setMessage("Debe ingresar un email válido!");
            setOpen(true);
        } 
        else {
            setLoading(true);
            window.emailjs.send(
                'gmail', 'email_estudiofuentes',
                { message_html: mensaje, from_name: nombre, from_email: email }
            ).then(res => {
                setVariant("success");
                setMessage("Su consulta ha sido enviada!");
                setOpen(true);
                setLoading(false);
            }).catch(err => {
                console.error('Error al enviar mail:', err)
                setVariant("error");
                setMessage("Error al enviar mail. Intente de nuevo por favor");
                setOpen(true);
                setLoading(false);
            })            
        }        
    }

    function validateEmail() {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Element name="contacto">
            <section className={classes.root}>
                <Container className={classes.container}>
                    <div>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <Typography variant="h5" >
                                    Contactanos
                                    </Typography>
                                    <br />
                                    <Typography>
                                        {'Estudio Jurídico Fuentes Tedesco & Asociados siempre buscará superar tus expectativas. '}
                                        {'¿Tenes alguna pregunta, comentario o solicitud? Queremos saberlo. ¡No dudes en contactarnos! '}
                                        <br /><br />
                                        {/*<LocationOn className={classes.contactIcon}/>{'Tucumán 944 piso 4H, Ciudad Autónoma de Buenos Aires'}
                                        <br /><br />*/}
                                        <ScheduleIcon className={classes.contactIcon}/>{'Lunes a Viernes de 9 a 18hs.'}
                                        <br /><br />
                                        <PhoneIcon className={classes.contactIcon}/>{'(+54 11) 5143-3836'}
                                        <br /><br />
                                        <MailOutlineIcon className={classes.contactIcon}/>{'consultas@estudiofuentestedesco.com'}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.item}>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    id="nombre"
                                                    label="Nombre"
                                                    className={classes.textField}
                                                    variant="outlined"
                                                    onChange={handleChangeNombre}
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    id="email"
                                                    label="Email"
                                                    className={classes.textField}
                                                    variant="outlined"
                                                    onChange={handleChangeEmail}
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    id="mensaje"
                                                    label="Mensaje"
                                                    multiline rows="4"
                                                    className={classes.textField}
                                                    variant="outlined"
                                                    onChange={handleChangeMensaje}
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={12}>
                                                <Box sx={{ m: 1, position: 'relative' }}>
                                                    <Button                                                   
                                                        className={classes.button}
                                                        onClick={handleSubmit}
                                                        disabled={loading}
                                                        variant="contained"                                                    
                                                        >
                                                        Enviar
                                                    </Button>
                                                    {loading && (
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                            color: 'black',
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '12%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            open={snackbarOpen}
                                            autoHideDuration={3000}
                                            onClose={handleClose}
                                        >
                                            <SnackbarContentWrapper
                                                onClose={handleClose}
                                                variant={snackbarVariant}
                                                message={snackbarMessage}
                                            />
                                        </Snackbar>
                                    </ThemeProvider>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
        </Element>
    );
}

export default withStyles(styles)(Contacto);