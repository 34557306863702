import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: 'white'
  },
  container: {
    /*marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',*/
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    fontWeight: 'bold'
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function QuienesSomos(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
        <Container className={classes.container}>
            <Typography variant="h4" marked="center" className={classes.title} component="h2">
            Quienes Somos
            </Typography>
            <div>
            <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                <div className={classes.item}>
                    <Typography variant="h6" align="center">
                    {'Somos profesionales del derecho, en constante crecimiento. Velamos por satisfacer las necesidades de nuestros'}
                    {' clientes, mediante asesoramiento y atención personalizada, con la confianza como eje rector. '}
                    {'Nuestro prestigio se basa en servicios excepcionales como nadie más los puede ofrecer. '}
                    </Typography>
                </div>
                </Grid>
            </Grid>
            </div>
        </Container>
    </section>
  );
}

export default withStyles(styles)(QuienesSomos);