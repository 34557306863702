import React from 'react';
import Header from './modules/views/Header';
import Banner from './modules/views/Banner';
import QuienesSomos from './modules/views/QuienesSomos';
import Areas from './modules/views/Areas';
import Objetivo from './modules/views/Objetivo';
import Contacto from './modules/views/Contacto';
import Footer from './modules/views/Footer';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Raleway',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

function Index() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Header />
        <Banner />
        <QuienesSomos />
        <Areas />
        <Objetivo />
        <Contacto />
        <Footer />
      </>
    </ThemeProvider>
  );
}

export default Index;
