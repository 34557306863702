import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: '#181818',
        color: 'white'
    },
    container: {
        /*marginTop: theme.spacing(15),
        marginBottom: theme.spacing(30),
        display: 'flex',
        position: 'relative',*/
        //marginTop: theme.spacing(10),
        //marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    /*item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //padding: theme.spacing(0, 5),
    },*/
    image: {
        width: '100%',
        height: '600px',
        [theme.breakpoints.down('sm')]: {
            height: '400px',
        },
    },
    title: {
        width: '100%'
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
    },
    gridItem: {
        padding: 0
    },
    containerDesc: {
        padding: '180px',
        [theme.breakpoints.down('sm')]: {
            padding: '50px',
        },
    }
});

function Objetivo(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div className={classes.containerDesc}>
                        <Typography variant="h5" className={classes.title}>
                            Nuestro Objetivo
                        </Typography>
                        <br />
                        <Typography>
                            {'Queremos que todos nuestros clientes puedan experimentar el nivel de profesionalismo de nuestro equipo. '}
                            {'La razón de ser de nuestros servicios, es mejorar tu vida y solucionar el inconveniente legal con el cual te encuentres. '}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.item}>
                        <img className={classes.image} src={require("../images/areas.jpeg")} alt="Objetivo" />
                    </div>
                </Grid>
            </Grid>
        </section>
    );
}

export default withStyles(styles)(Objetivo);