import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Link from '@material-ui/core/Link';

function Copyright() {
    return (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            {'©'}{new Date().getFullYear()}{' Estudio Jurídico Fuentes Tedesco & Asociados. Desarrollado por '}
            <a href="https://www.linkedin.com/in/gabriel-ferreira-29b05367" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Gabriel Ferreira</a>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: '#181818',
    },
    container: {
        //marginTop: theme.spacing(8),
        //marginBottom: theme.spacing(8),
        display: 'flex',
        maxWidth: '100%'
        //marginLeft: 0,
        //marginRight: 0
    },
    copyright: {
        color: 'white',
        //fontWeight: 'bold'
    },
    wpButton: {
        position: 'fixed',
        width: '60px',
        height: '60px',
        bottom: '40px',
        right: '40px',
        backgroundColor: '#25d366',
        color: '#FFF',
        borderRadius: '50px',
        textAlign: 'center',
        fontSize: '30px',
        boxShadow: '2px 2px 3px #999',
        'z-index': '100',
        '&:hover': {
            backgroundColor: '#25d366'
        }   
    },
}));


export default function Footer() {
    const classes = useStyles();
    return (
        <>
            <Typography component="footer" align="center" className={classes.root}>
                <Container className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid item className={classes.copyright}>
                                <Copyright />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Typography>
            <Link href="https://wa.me/541151433836?text=Hola,%20estoy%20interesado%20en%20sus%20servicios" target="_blank" rel="noopener noreferrer" /*color="inherit"*/>
                <IconButton className={classes.wpButton} /*color="inherit" aria-label="menu"*/>
                    <WhatsAppIcon />
                </IconButton>
            </Link>
        </>
    );
}