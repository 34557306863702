import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BannerLayout from './BannerLayout';
import Typography from '@material-ui/core/Typography';

const backgroundImage = require("../images/servicios.jpg");

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
    //width: '100%',
    backgroundSize: 'cover',
  },
});

function Banner(props) {
  const { classes } = props;
  
  return (
    <BannerLayout backgroundClassName={classes.background}>
      <Typography color="inherit" align="center" variant="h2" className={classes.title}>
        Fuentes Tedesco & Asociados
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.title}>
        Estudio Jurídico Integral
      </Typography>
    </BannerLayout>
  );
}

export default withStyles(styles)(Banner);